import { Component, EventEmitter, Input, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import {
    AbstractControl, ControlValueAccessor, NG_VALIDATORS,
    NG_VALUE_ACCESSOR, ValidationErrors, Validator
} from '@angular/forms';
import { EDITOR_SIZE } from '../../../constant/shared-constant';
import { EditorComponent } from '@tinymce/tinymce-angular';

/*The default width is 794px. I have set 26px left and right inner space, so the total page width is 846px (adding 52px).*/
const defaultWidth = 846;
interface LineHeightOptions {
    lineHeight: 1 | 1.1 | 1.2 | 1.3 | 1.4 | 1.5 | 2;
}

@Component({
    selector: 'app-form-control-tinymce-editor',
    template: `
  <div class="form-group" [class.has-error]="hasError()">
  <label *ngIf="label" [for]="label">{{ label }}
      <span *ngIf="required" class="required_field ms-1">*</span>
  </label>
  <editor  
  [class]="(label ? '' : 'm_t')" 
  [(ngModel)]= "value"
  [disabled]="disabled"
  (blur)="onTouched()"
  (ngModelChange)="tinyChangeEvent($event)"
  [init]="init"
  [id]="label"
 >
  </editor>

  <ng-container *ngIf="hasError()">
      <app-validation-error [errors]="control?.errors" [showLength]=false  [errorMessage]= "errorMessage" [fieldName]="fieldName">
      </app-validation-error>
  </ng-container>
</div>`,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormControlTinymceEditorComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FormControlTinymceEditorComponent),
            multi: true
        }
    ],
})
export class FormControlTinymceEditorComponent implements OnInit, ControlValueAccessor, Validator {
    @Input() label!: string;
    @Input() required?: boolean | false;
    @Input() fieldName!: string;
    @Input() errorMessage!: string;
    @Input() isDisabled!: boolean;
    @Input() formControlName!: string;
    @Input() editorSize!: string;
    @Input() height!: string;
    @Input() isPDFLayout = false;
    @Input() lineHeight: LineHeightOptions['lineHeight'] = 1.4;
    @Input() showPreview = true;

    @Output() changeEvent = new EventEmitter<any>();

    value: any;
    onChange: any = () => { };
    onTouched: any = () => { };
    control: AbstractControl | undefined;
    disabled: boolean;

    init!: any;

    constructor() {
        this.disabled = this.isDisabled;
        this.label = '';
    }

    ngOnInit(): void {
        this.initializeEditorConfig();
    }

    @ViewChild(EditorComponent) tinyEditor!: EditorComponent;

    insertText(inputString: string) {
        this.tinyEditor.editor.execCommand('mceInsertContent', false, inputString);
    }

    initializeEditorConfig() {
        const style = this.getEditorCustomStyles(this.isPDFLayout);
        if (this.editorSize === EDITOR_SIZE.large) {
            this.init = {
                setup: (editor: any) => this.isPDFLayout ? this.bindContentEvents(editor) : null,
                base_url: '/tinymce',
                suffix: '.min',
                promotion: false,
                toolbar_mode: 'sliding',
                height: this.height ?? "650px",
                removed_menuitems: 'codeformat fontfamily',
                menubar: 'file edit insert view format table help',
                toolbar: 'undo redo | bold italic underline strikethrough lineheight | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | forecolor backcolor removeformat pagebreak| quickimage quicktable |  charmap link fullscreen preview',
                plugins: this.getPlugins(),
                quickbars_insert_toolbar: false,
                importcss_append: true,
                convert_urls: false,
                lineheight_formats: '1 1.1 1.2 1.3 1.4 1.5 2',
                font_family_formats: 'Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Impact=impact,chicago; Tahoma=tahoma,arial,helvetica,sans-serif; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva;',
                content_style: style,
                contextmenu: 'copy cut paste link',
                cache_suffix: '?v=1.0.1'
                //https://www.tiny.cloud/docs/tinymce/latest/user-formatting-options/#font_formats font family refference
            }
        }
        else if (this.editorSize === EDITOR_SIZE.medium) {
            this.init = {
                setup: (editor: any) => this.isPDFLayout ? this.bindContentEvents(editor) : null,
                selector: '#' + this.formControlName,
                base_url: '/tinymce',
                suffix: '.min',
                promotion: false,
                toolbar_mode: 'sliding',
                height: this.height ?? "350px",
                toolbar: 'undo redo | bold italic underline strikethrough lineheight | alignleft aligncenter alignright alignjustify| fontfamily fontsize | numlist bullist | outdent indent | forecolor backcolor removeformat | charmap link fullscreen preview',
                menubar: '',
                plugins: 'preview powerpaste casechange importcss searchreplace autolink fullscreen link table hr advlist lists checklist wordcount tinymcespellchecker a11ychecker textpattern noneditable help formatpainter permanentpen pageembed  mentions linkchecker emoticons advtable export',
                convert_urls: false,
                lineheight_formats: '1 1.1 1.2 1.3 1.4 1.5 2',
                font_family_formats: 'Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Impact=impact,chicago; Tahoma=tahoma,arial,helvetica,sans-serif; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva;',
                content_style: style,
                cache_suffix: '?v=1.0.1'
            }
        }
        else {
            this.init = {
                setup: (editor: any) => this.isPDFLayout ? this.bindContentEvents(editor) : null,
                base_url: '/tinymce',
                suffix: '.min',
                promotion: false,
                toolbar_mode: 'sliding',
                height: this.height ?? "300px",
                toolbar: 'undo redo | bold italic underline strikethrough lineheight | fontfamily fontsize | alignleft aligncenter alignright alignjustify | numlist bullist checklist forecolor backcolor',
                menubar: '',
                plugins: 'autolink fullscreen link template table charmap hr anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker textpattern noneditable formatpainter permanentpen mentions linkchecker emoticons',
                convert_urls: false,
                lineheight_formats: '1 1.1 1.2 1.3 1.4 1.5 2',
                font_family_formats: 'Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Impact=impact,chicago; Tahoma=tahoma,arial,helvetica,sans-serif; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva;',
                content_style: style,
                cache_suffix: '?v=1.0.1'
            }
        }
    }

    bindContentEvents(editor: any) {

        editor.on('NodeChange keydown keyup', function () {
            const contentBody = editor.getDoc().body;

            // Process images
            const images = contentBody.querySelectorAll('img');
            images?.forEach(function (img: HTMLImageElement) {
                img.onload = function () {
                    const renderedWidth = img.offsetWidth;
                    if (renderedWidth > defaultWidth) {
                        img.setAttribute('width', '100%');
                        img.removeAttribute('height');
                    }
                };
                if (img.complete) {
                    const renderedWidth = img.offsetWidth;
                    if (renderedWidth > defaultWidth) {
                        img.setAttribute('width', '100%');
                        img.removeAttribute('height');
                    }
                }
            });

             // Process Table
            const tables = contentBody.querySelectorAll('table');
            tables?.forEach(function (table: any) {
              table.style.width = '100%';
              table.style.tableLayout = 'fixed';
            });

        });
    }

    validate(control: AbstractControl<any, any>): ValidationErrors | null {
        this.control = control;
        return null;
    }

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    tinyChangeEvent(event: any) {
        this.control?.markAsTouched();
        this.onChange(event);
        this.changeEvent.emit(event);
    }

    hasError(): boolean | undefined {
        return this.control?.invalid && (this.control?.touched || this.control?.dirty);
    }

    getEditorCustomStyles(isTrue: boolean): string {
        const defaultStyle = `p { margin: 0 !important; } body{line-height : ${this.lineHeight}}`;
        const bodyStyle = `
          body {
            width: ${defaultWidth}px;
            margin: 25px auto;
            padding: 26px;
            box-sizing: border-box;
            position: relative;
            border: 2px solid #d0d0d0;
            min-height: 1122px;
          }
          body * {
            white-space: normal !important;
            word-wrap: break-word !important;
            word-break: break-word !important;
         }
         table{max-width: 100% !important; table-layout: fixed !important;}
         table img {max-width: 100% !important;}
        `;
        return isTrue ? `${defaultStyle} ${bodyStyle}` : defaultStyle;
    }

    getPlugins(){
        if (this.showPreview){
             return 'preview powerpaste casechange importcss searchreplace autolink directionality visualblocks visualchars fullscreen image link table charmap hr pagebreak nonbreaking insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools noneditable help permanentpen pageembed charmap mentions quickbars linkchecker advtable export'
        }
        else{
            return 'powerpaste casechange importcss searchreplace autolink directionality visualblocks visualchars fullscreen image link table charmap hr pagebreak nonbreaking insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools noneditable help permanentpen pageembed charmap mentions quickbars linkchecker advtable export'
        }
    }
}
