<div class="d-flex flex-wrap gap-2">

    <button type="button"
        class="btn btn-sm btn-icon d-inline-flex align-items-center justify-content-center wid-30 hei-25"
        [ngClass]="patientInfo?.hasPrimaryContact ? 'btn-light-success' : 'btn-light-danger'"
        ngxTippy="{{ patientInfo?.hasPrimaryContact ? 'Primary Contact' : 'No Primary Contact' }}"  [tippyProps]="{ trigger: 'mouseenter', interactive: true, zIndex: tippyZIndex}"
        (click)="onClickIcon('PatientInformation');" >
        PC
    </button>
    <button type="button"
        class="btn btn-sm btn-icon d-inline-flex align-items-center justify-content-center wid-30 hei-25"
        [ngClass]="patientInfo?.hasFinancialResponsiblePerson ? 'btn-light-success' : 'btn-light-danger'"
        ngxTippy="{{ patientInfo?.hasFinancialResponsiblePerson ? 'FRP' : 'No FRP' }}" [tippyProps]="{ trigger: 'mouseenter', interactive: true, zIndex: tippyZIndex}"
        (click)="onClickIcon('PatientFRPInformation');">
        F
    </button>
    <button type="button" *ngIf="!fromTreatmentPlan"
        class="btn btn-sm btn-icon d-inline-flex align-items-center justify-content-center wid-30 hei-25"
        [ngClass]="{'btn-light-success': patientInfo?.medicalHistoryStatus==='Valid',
                    'btn-light-warning': patientInfo?.medicalHistoryStatus==='Expired',
                    'btn-light-secondary': !patientInfo?.medicalHistoryStatus || patientInfo?.medicalHistoryStatus==='NotAvailable'}" 
        ngxTippy="{{getMedicalHistoryTooltip()}}" [tippyProps]="{ trigger: 'mouseenter', interactive: true, zIndex: tippyZIndex}"  
        (click)="onClickIcon('PatientMedicalHistory'); "> 
        M
    </button>
    <button type="button" *ngIf="!fromTreatmentPlan"
        class="btn btn-sm btn-icon d-inline-flex align-items-center justify-content-center wid-30 hei-25"
        [ngClass]="patientInfo?.hasChiefComplaint ? 'btn-light-success' : 'btn-light-danger'"
        ngxTippy="{{ patientInfo?.hasChiefComplaint ? 'Chief Complaint' : 'No Chief Complaint' }}"  [tippyProps]="{ trigger: 'mouseenter', interactive: true, zIndex: tippyZIndex}"
        (click)="onClickIcon('ChiefComplaint');">
        CC
    </button>
    <button type="button" class="btn btn-sm btn-icon d-inline-flex align-items-center justify-content-center wid-30 hei-25"
        [ngClass]="{
                'btn-light-success': patientInfo?.patientEligibilityStatusName === insuranceVerificationCategory.verified,
                'btn-warning': patientInfo?.patientEligibilityStatusName === insuranceVerificationCategory.moreInformationNeeded
                    || patientInfo?.patientEligibilityStatusName===insuranceVerificationCategory.unabletoVerify,
                'btn-light-secondary': !patientInfo?.hasInsurance,
                'btn-light-warning': patientInfo?.patientEligibilityStatusName === insuranceVerificationCategory.notVerified
                    || (!patientInfo?.patientEligibilityStatusName && patientInfo?.hasInsurance),
                'btn-secondary': patientInfo?.patientEligibilityStatusName === insuranceVerificationCategory.noInsurance,
                'btn-danger': patientInfo?.patientEligibilityStatusName === insuranceVerificationCategory.ageLimit 
                    || patientInfo?.patientEligibilityStatusName === insuranceVerificationCategory.lTMReached 
                    || patientInfo?.patientEligibilityStatusName === insuranceVerificationCategory.insuranceNotExist,
                'btn-light-purple': patientInfo?.patientEligibilityStatusName === insuranceVerificationCategory.preAuthorization,
                'btn-light-primary': patientInfo?.patientEligibilityStatusName === insuranceVerificationCategory.inProgress,
                'btn-light-pink': patientInfo?.patientEligibilityStatusName === insuranceVerificationCategory.paused,
                'btn-primary': patientInfo?.patientEligibilityStatusName === insuranceVerificationCategory.coPayBased,
                'btn-yellow': patientInfo?.patientEligibilityStatusName === insuranceVerificationCategory.reVerify}"
        ngxTippy="{{ patientInfo?.hasInsurance ? 'Insurance' : 'No Insurance' }}"
        [tippyProps]="{ trigger: 'mouseenter', interactive: true, zIndex: tippyZIndex }" (click)="onClickIcon('Insurance')">
        I
    </button>
    <button type="button" *ngIf="!fromTreatmentPlan"
        class=" btn btn-sm btn-icon d-inline-flex align-items-center justify-content-center wid-30 hei-25"
        [ngClass]="patientInfo?.hasPaymentPlan ? 'btn-light-success' : 'btn-light-danger'"
        ngxTippy="{{ patientInfo?.hasPaymentPlan ? 'Payment Plan' : 'No Payment Plan' }}" [tippyProps]="{ trigger: 'mouseenter', interactive: true, zIndex: tippyZIndex}"  
        (click)="onClickIcon('TreatmentContract');">
        PP
    </button>
    <button type="button" *ngIf="!fromTreatmentPlan"
        class=" btn btn-sm btn-icon d-inline-flex align-items-center justify-content-center wid-30 hei-25"
        [ngClass]="patientInfo?.hasTreatmentContract ? 'btn-light-success' : 'btn-light-danger'"
        ngxTippy="{{ patientInfo?.hasTreatmentContract ? 'Service Contract' : 'No Service Contract' }}"  [tippyProps]="{ trigger: 'mouseenter', interactive: true, zIndex: tippyZIndex}"
        (click)="onClickIcon('TreatmentContract');">
        SC 
    </button>
    <button type="button" *ngIf="referralPatient"
        class=" btn btn-sm btn-icon d-inline-flex align-items-center justify-content-center wid-30 hei-25"
        (click)="onClickIcon('PatientReferal');"[ngClass]="patientInfo?.hasReferral ? 'btn-light-success' : 'btn-light-danger'"
        ngxTippy="{{ patientInfo?.hasReferral ? 'Referral Information' : 'No Referral Information' }}" [tippyProps]="{ trigger: 'mouseenter', interactive: true, zIndex: tippyZIndex}">
        R 
    </button>
</div>