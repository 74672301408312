import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { FormControlCheckboxComponent } from './components/form-controls/form-control-checkbox/form-control-checkbox.component';
import { FormControlTextboxComponent } from './components/form-controls/form-control-textbox/form-control-textbox.component';
import { FormControlTextareaComponent } from './components/form-controls/form-control-textarea/form-control-textarea.component';
import { FormControlTextmaskComponent } from './components/form-controls/form-control-textmask/form-control-textmask.component';
import { FormControlSubmitComponent } from './components/form-controls/form-control-submit/form-control-submit.component';
import { BrowserDownloadButtonComponent } from './components/form-controls/browser-download-button/browser-download-button.component';
import { UsStatesDropdownComponent } from './components/core-lookup/us-states-dropdown/us-states-dropdown.component';
import { TimezoneDropdownComponent } from './components/core-lookup/timezone-dropdown/timezone-dropdown.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { FormControlSelectboxComponent } from './components/form-controls/form-control-selectbox/form-control-selectbox.component';
import { SessionTimeoutComponent } from './components/error-pages/session-timeout.component';
import { TableSkeletonComponent } from './components/spinner/table-skeleton/table-skeleton.component';
import { FormControlRadioButtonComponent } from './components/form-controls/form-control-radio-button/form-control-radio-button.component';
import { FormControlToggleButtonComponent } from './components/form-controls/form-control-toggle-button/form-control-toggle-button.component';
import { PopupModalComponent } from './components/modal/popup-modal.component';

import { AlertService } from './utils/alert.service';
import { CommonUtilsService } from './utils/common-utils.service';
import { CustomValidatorService } from './utils/custom-validator.service';
import { NoPrefixWhitespaceDirective } from './directive/no-prefix-whitespace.directive';
import { ScrollToFirstInvalidDirective } from './directive/scroll-to-first-invalid.directive';
import { PhoneNumberMaskRemoverDirective } from './directive/remove-phonenumber-masking.directive';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { coreLookupReducer } from './store/core-lookup-store/core-lookup.reducer';
import { CoreLookupEffect } from './store/core-lookup-store/core-lookup.effect';
import { BaseDestroyComponent } from './components/base-destroy/base-destroy.component';
import { NoRecordComponent } from './components/error-pages/no-record.component';

import { InlineTextBoxComponent } from './components/form-controls/inline-text-box/inline-text-box.component';
import { LookupEditListSkeletonComponent } from './components/spinner/lookup-edit-list-skeleton/lookup-edit-list-skeleton.component';
import { NumericOnlyDirective } from './directive/numeric-only.directive';
import { InlineTextAreaComponent } from './components/form-controls/inline-text-area/inline-text-area.component';
import { FormControlTinymceEditorComponent } from './components/form-controls/form-control-tinymce-editor/form-control-tinymce-editor.component';
import { FormControlNumberTextboxComponent } from './components/form-controls/form-control-number-textbox/form-control-number-textbox.component';
import { PatientAgePipe } from './pipes/patient-age.pipe';
import { FormControlPasswordTextboxComponent } from './components/form-controls/form-control-password-textbox/form-control-password-textbox.component';
import { NoPermissionComponent } from './components/error-pages/no-permission.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { MultiselectCheckboxComponent } from './components/form-controls/multiselect-checkbox/multiselect-checkbox.component';
import { InitialsPipe } from './pipes/initials.pipe';
import { OrdinalDatePipe } from './pipes/ordinal-date.pipe';
import { CustomSortPipe } from './pipes/custom-sort.pipe';
import { PaymentStepsSkeletonComponent } from './components/spinner/payment-steps-skeleton/payment-steps-skeleton.component';
import { HtmlbuildService } from './services/html-build.service';
import { AppLocalStorageService } from './services/app-local-storage.service';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { NumericDecimalOnlyDirective } from './directive/numeric-decimal-only.directive';
import { AppSessionStorageService } from './services/app-session-storage.service';
import { PrintHtmlContentComponent } from './components/print-html/print-html-content/print-html-content.component';
import { DateTimeFormatPipe } from './pipes/date-time-format.pipe';
import { NavigateURLService } from './services/navigate-url.service';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { ShortMonthFormattedDatePipe } from './pipes/short-month-formatted-date.pipe';
import { MeridianTimeFormatPipe } from './pipes/meridian-time-format.pipe';
import { TimerComponent } from './components/timer/timer.component';
import {
  PrintAvoidBreakDirective, PrintBtnDirective, PrintHideDirective,
  PrintLandscapeDirective, PrintOnlyDirective, PrintRemoveDirective, PrintSectionDirective
} from './directive/print.directives';
import { DialogModalComponent } from './components/modal/dialog-modal.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ChatSkeletonComponent } from './components/spinner/chat-skeleton/chat-skeleton.component';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { NgbDropdownActionComponent } from './components/ngbDropdown-action/ngb-dropdown-action/ngb-dropdown-action.component';
import { NgbpopupActionComponent } from './components/ngbpopup-action/ngbpopup-action.component';
import { NumberMaskDirective } from './directive/number-mask.directive';

import { MonthlyCalendarComponent } from './components/monthly-calendar/monthly-calendar.component';
import { ApplicationFeatureDirective } from './directive/application-feature.directive';
import { ContractLedgerComponent } from '../contract-plan-dashboard/component/contract-ledger/contract-ledger.component';
import { ContractLedgerPrintComponent } from '../contract-plan-dashboard/component/contract-ledger-print/contract-ledger-print.component';
import { CustomWizardComponent } from './components/custom-wizard/custom-wizard.component';
import { PopupListModalComponent } from './components/modal/popup-list-modal.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

//We will remove this after the custom stepper component is fully integrated.
import { FirstComponent } from './components/custom-wizard/first/first.component';
import { SecondComponent } from './components/custom-wizard/second/second.component';
import { ThirdComponent } from './components/custom-wizard/third/third.component';
import { FirstChildComponent } from './components/custom-wizard/first-child/first-child.component';
import { SampleWizardComponent } from './components/custom-wizard/sample-wizard/sample-wizard.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { FilterDatasetComponent } from './components/Filter-Dataset.Component/filter.dataset.component';
import { CoreAppFormFeatureModule } from './module/core-app-form-feature.module';

@NgModule({
  declarations: [
    SessionTimeoutComponent,
    FormControlCheckboxComponent,
    FormControlTextboxComponent,
    FormControlTextareaComponent,
    FormControlTextmaskComponent,
    FormControlSubmitComponent,
    BrowserDownloadButtonComponent,
    UsStatesDropdownComponent,
    TimezoneDropdownComponent,
    FormControlSelectboxComponent,
    TableSkeletonComponent,
    OverlayComponent,
    FormControlSelectboxComponent,
    FormControlRadioButtonComponent,
    FormControlToggleButtonComponent,
    NoPrefixWhitespaceDirective,
    ScrollToFirstInvalidDirective,
    PopupModalComponent,
    DialogModalComponent,
    RelativeTimePipe,
    PhoneNumberMaskRemoverDirective,
    PhoneNumberPipe,
    BaseDestroyComponent,
    NoRecordComponent,
    InlineTextBoxComponent,
    LookupEditListSkeletonComponent,
    FilterPipe,
    PhoneNumberPipe,
    SafeHtmlPipe,
    NumericOnlyDirective,
    InlineTextAreaComponent,
    FormControlTinymceEditorComponent,
    FormControlNumberTextboxComponent,
    PatientAgePipe,
    TimeFormatPipe,
    FormControlPasswordTextboxComponent,
    NoPermissionComponent,
    MultiselectCheckboxComponent,
    InitialsPipe,
    OrdinalDatePipe,
    CustomSortPipe,
    PaymentStepsSkeletonComponent,
    NumericDecimalOnlyDirective,
    PrintHtmlContentComponent,
    DateTimeFormatPipe,
    MeridianTimeFormatPipe,
    ShortMonthFormattedDatePipe,
    TimerComponent,
    DatePickerComponent,
    ChatSkeletonComponent,
    //PrintDirectives
    PrintSectionDirective,
    PrintHideDirective,
    PrintRemoveDirective,
    PrintOnlyDirective,
    PrintAvoidBreakDirective,
    PrintBtnDirective,
    PrintLandscapeDirective,
    TimeAgoPipe,
    NgbDropdownActionComponent,
    NgbpopupActionComponent,
    NumberMaskDirective,
    MonthlyCalendarComponent,
    ApplicationFeatureDirective,
    ContractLedgerComponent,
    ContractLedgerPrintComponent,
    CustomWizardComponent,
    AvatarComponent,
    //We will remove this after the custom stepper component is fully integrated.
    FirstComponent,
    SecondComponent,
    ThirdComponent,
    FirstChildComponent,
    SampleWizardComponent,
    PopupListModalComponent,
    FilterDatasetComponent,
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    NgSelectModule,
    RouterModule,
    FormsModule,
    EditorModule,
    StoreModule.forFeature('coreLookupStore', coreLookupReducer),
    EffectsModule.forFeature([CoreLookupEffect]),
    NgxTippyModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgScrollbarModule,
    CoreAppFormFeatureModule
  ],
  providers: [
    AlertService,
    CommonUtilsService,
    CustomValidatorService,
    HtmlbuildService,
    AppLocalStorageService,
    AppSessionStorageService,
    NavigateURLService,
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
  ],
  exports: [
    FormControlCheckboxComponent,
    FormControlTextboxComponent,
    FormControlTextareaComponent,
    FormControlTextmaskComponent,
    FormControlSubmitComponent,
    BrowserDownloadButtonComponent,
    UsStatesDropdownComponent,
    TimezoneDropdownComponent,
    FormControlSelectboxComponent,
    TableSkeletonComponent,
    OverlayComponent,
    NoPrefixWhitespaceDirective,
    ScrollToFirstInvalidDirective,
    PopupModalComponent,
    DialogModalComponent,
    RelativeTimePipe,
    PhoneNumberMaskRemoverDirective,
    FormControlRadioButtonComponent,
    PhoneNumberPipe,
    FilterPipe,
    SafeHtmlPipe,
    FormControlToggleButtonComponent,
    BaseDestroyComponent,
    NoRecordComponent,
    InlineTextBoxComponent,
    LookupEditListSkeletonComponent,
    NumericOnlyDirective,
    InlineTextAreaComponent,
    FormControlTinymceEditorComponent,
    FormControlNumberTextboxComponent,
    FormControlPasswordTextboxComponent,
    MultiselectCheckboxComponent,
    PatientAgePipe,
    TimeFormatPipe,
    FormControlPasswordTextboxComponent,
    InitialsPipe,
    OrdinalDatePipe,
    CustomSortPipe,
    PaymentStepsSkeletonComponent,
    NgxTippyModule,
    NumericDecimalOnlyDirective,
    PrintHtmlContentComponent,
    DateTimeFormatPipe,
    MeridianTimeFormatPipe,
    ShortMonthFormattedDatePipe,
    TimerComponent,
    DatePickerComponent,
    ChatSkeletonComponent,
    TimeAgoPipe,
    //PrintDirectives
    PrintSectionDirective,
    PrintHideDirective,
    PrintRemoveDirective,
    PrintOnlyDirective,
    PrintAvoidBreakDirective,
    PrintBtnDirective,
    PrintLandscapeDirective,
    NgbDropdownActionComponent,
    NgbpopupActionComponent,
    NumberMaskDirective,
    MonthlyCalendarComponent,
    ApplicationFeatureDirective,
    ContractLedgerComponent,
    ContractLedgerPrintComponent,
    CustomWizardComponent,
    PopupListModalComponent,
    AvatarComponent,
    //We will remove this after the custom stepper component is fully integrated.
    SampleWizardComponent,
    FilterDatasetComponent,
    CoreAppFormFeatureModule
  ]
})
export class SharedFeatureModule { }