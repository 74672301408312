import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { dateFormat } from "../constant/shared-constant";
import moment from "moment";

@Injectable({
    providedIn: 'root',
})

export class DateUtilService {
    constructor( private datePipe:DatePipe) { }

    public MILLISECONDS_IN_A_DAY = 1000 * 3600 * 24;

    public GetApplicationCurrentDateTime(): Date {
        return new Date();
    }

    public GetApplicationCurrentDate12AM(): Date {
        const date = this.GetApplicationCurrentDateTime();
        date.setHours(0, 0, 0, 0);
        return date;
    }

    public GetApplicationCurrentDateTime12AM(): Date {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        return date;
    }

    public GetApplicationTomorrowDateTime(): Date {
        const currentDate = new Date(this.GetApplicationCurrentDateTime());
        const tomorrowDate  = new Date(currentDate);
        tomorrowDate.setDate(currentDate.getDate() + 1)
        return tomorrowDate;
    }

    private GetCurrentDateTime(): Date {
        return this.GetApplicationCurrentDateTime();
    }

    public GetCurrentUTCDateTime(): Date {
        const now = this.GetApplicationCurrentDateTime();
        return new Date(now.toUTCString());
    }

    public convertToUTCDateTimeFormat(date: string): string {
        if (!date.toLowerCase().includes('z')) {
            date += 'Z';
        }
        return date;
    }

    public GetStartDateOfMonth(date: Date = this.GetApplicationCurrentDateTime()): string {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Intl.DateTimeFormat('en-US', options).format(new Date(date.getFullYear(), date.getMonth(), 1));
    }

    public GetStartDateOfTheMonthAsDate(date: Date = this.GetApplicationCurrentDateTime()): Date {
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    public GetStartDateOfTheYearAsDate(date: Date = this.GetApplicationCurrentDateTime()): Date {
        return new Date(date.getFullYear(), 0, 1);
    }

    public GetEndDateOfMonth(date: Date = this.GetApplicationCurrentDateTime()): string {
        const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Intl.DateTimeFormat('en-US', options).format(lastDayOfMonth);
    }

    public ConvertTimeStringToDate(timeString: string): Date {
        const currentDate = this.GetApplicationCurrentDateTime();
        const dateTimeString = `${currentDate.toDateString()} ${timeString}`;
        return new Date(dateTimeString);
    }

    public DateWithTimeFormat() {
        return "yyyy-MM-ddTHH:mm:ss";
    }

    public getDateWithStartTime(dateString: string): Date {
        const dateWithTime = new Date(dateString);
        dateWithTime.setHours(0, 0, 0);
        return dateWithTime;
    }

    public getDateWithEndTime(dateString: string): Date {
        const dateWithTime = new Date(dateString);
        dateWithTime.setHours(23, 59, 59);
        return dateWithTime;
    }

    public getTimeDifference(fromDate: string, toDate: string): number {
        return new Date(toDate).getTime() - new Date(fromDate).getTime();
    }

    public getTimeDifferenceByDate(fromDate: Date, toDate: Date): number {
        return new Date(toDate).getTime() - new Date(fromDate).getTime();
    }

    public getTimeDifferenceInDays(fromDate: Date, toDate: Date): number {
        const timeDifference = new Date(toDate).getTime() - new Date(fromDate).getTime();
        const daysDifference = timeDifference / (1000 * 3600 * 24);
        return Math.floor(daysDifference);
    }

    public getTimeDifferenceFromNow(date: string, isUTC = false): number {
        if (isUTC) {
            return this.GetCurrentUTCDateTime().getTime() - new Date(date).getTime();
        }
        return this.GetCurrentDateTime().getTime() - new Date(date).getTime();
    }

    getYearFormat() {
        return "yyyy-MM-dd"
    }

    public ConvertDatetimeToString(date: string | null): string {
        if (date) {
            const parsedDate = new Date(date);
            if (!isNaN(parsedDate.getTime())) {
                return new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: '2-digit'
                }).format(parsedDate);
            } else {
                return 'Invalid Date';
            }
        }

        return '';
    }
    
    setStartHoursOfTheDay(date: Date) {
        date.setHours(0, 0, 0, 0); // Set time to 00:00:00
        return date;
    }

    setEndHoursOfTheDay(date: Date) {
        date.setHours(23, 59, 59, 999); // Set time to 23:59:59
        return date;
    }

    getStartDayOfTheYear(year: number) {
        return new Date(year, 0, 1);
    }

    getEndDateOftheYear(year: number) {
        return new Date(year, 12, 0);
    }

    getMonthFormat() {
        return  "MM/dd/YYYY"
    }

    getTimeDifferenceInMinutes(startDate: string, endDate: string) {
        const timeDifference = this.getTimeDifference(startDate, endDate);
        const durationInMinutes = timeDifference / (1000 * 60);
        return durationInMinutes;
    }

    getTimeDifferenceInMinutesByDate(startDate: Date, endDate: Date) {
        const timeDifference = this.getTimeDifferenceByDate(startDate, endDate);
        const durationInMinutes = timeDifference / (1000 * 60);
        return durationInMinutes;
    }

    getDaysInMonth(date: Date) {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    }

    getDaysInLastMonth(date: Date) {
        return new Date(date.getFullYear(), date.getMonth(), 0).getDate();
    }

    getFirstDayOfTheMonth(date: Date) {
        return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    }

    getAllDaysInMonth(date: Date) {
        const daysInMonth = this.getDaysInMonth(date);
        return Array.from({ length: daysInMonth }, (_, i) => (
            new Date(date.getFullYear(), date.getMonth(), i + 1)
        ));
    }

    getShortMonthFormattedDate(inputDate: string | null | undefined) {
        if (!inputDate)
            return "";

        const date = new Date(inputDate);
        if (isNaN(date.getDate())) {
            return "Invalid date";
        }

        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    }

    isSameDate(start: string, end: string): boolean {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const startYear = startDate.getFullYear();
        const startMonth = startDate.getMonth();
        const startDay = startDate.getDate();
        const endYear = endDate.getFullYear();
        const endMonth = endDate.getMonth();
        const endDay = endDate.getDate();
        return startYear === endYear && startMonth === endMonth && startDay === endDay;
    }

    isDifferentMonth(existingDate: Date, newDate: Date) {
        const existingMonth = existingDate.getMonth();
        const existingYear = existingDate.getFullYear();
        const newMonth = newDate.getMonth();
        const newYear = newDate.getFullYear();
        return existingMonth !== newMonth || existingYear !== newYear;
    }

    get24HoursFormatTimeFromDate(date: Date) {
        return this.datePipe.transform(date, 'HH:mm') || '';
    }

    setTimeInDate(date: Date, time: string): Date {
        const [hours, minutes] = time.split(':').map(Number);
        const updatedDate = new Date(date.getTime());
        updatedDate.setHours(hours);
        updatedDate.setMinutes(minutes);
        return updatedDate;
    }
    
    setTimeInDateByDateString(date: string, time: string): Date {
       return this.setTimeInDate(new Date(date), time);
    }

    getDateTimeString(date: Date): string {
        return this.datePipe.transform(date, this.DateWithTimeFormat()) ?? '';
    }
    
    addDaysWithDate(date: Date, numberOfDays: number){
       return this.datePipe.transform(date.setDate(date.getDate() + numberOfDays), dateFormat.date)!;
    }

    addDaysWithDateAsDate(date: Date, numberOfDays: number): Date{
        date.setDate(date.getDate() + numberOfDays);
        return date;
     }

    addMonthsWithDate(date: Date, numberOfMonths: number){
        return this.datePipe.transform(date.setMonth(date.getMonth() + numberOfMonths), dateFormat.date)!;
    }

    addWeeksWithDateAsDate(date: Date, numberOfWeeks: number) : Date{
        date.setDate(date.getDate() + (numberOfWeeks * 7));
        return date;
    }

    addWeeksWithDate(date: Date, numberOfWeeks: number) : Date {
        date.setDate(date.getDate() + (numberOfWeeks * 7));
        return date;
    }

    formatDateTime(dateTimeStr: string): string {
        const dateTime = new Date(dateTimeStr);
        const currentDate = this.GetApplicationCurrentDateTime();
      
        const diffMillis = currentDate.getTime() - dateTime.getTime();
        const diffMinutes = Math.floor(diffMillis / (1000 * 60));
        const diffHours = Math.floor(diffMillis / (1000 * 60 * 60));
        const diffDays = Math.floor(diffMillis / (1000 * 60 * 60 * 24));
      
        if (diffMinutes < 1) {
          return 'Just now';
        } else if (diffMinutes <= 60) {
          return `${diffMinutes} minutes ago`;
        } else if (diffHours <= 24) {
          const formattedTime = dateTime.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          });
          return `Today ${formattedTime}`;
        }  else if (diffDays == 1) {
            return `${diffDays} day ago`; 
        }
        else if (diffDays <= 7) {
          return `${diffDays} days ago`;
        } else {
          const formattedDate = dateTime.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          });
          return `${formattedDate}`;
        }
      }

      getStartOfWeek(): Date {
        const date = this.GetApplicationCurrentDateTime();
        date.setHours(0, 0, 0, 0);
        const dayOfWeek = date.getDay(); 
        const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
        return new Date(date.setDate(diff));
      }

      getEndOfWeek(): Date {
        const startOfWeek = this.getStartOfWeek();
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);
        return endOfWeek;
      }

    formatDateMMDDYYYYByDateAsInput(inputDate: Date | undefined): string {
        if(inputDate)
            return this.formatDateMMDDYYYY(inputDate?.toString());  
        else
            return "";
    }

      formatDateMMDDYYYY(inputDate: string): string {
        if (!inputDate)
            return "";
        const date = new Date(inputDate);
        if (isNaN(date.getDate())) {
            return "Invalid date";
        }
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    }

    convertMilliSecondsToHour(milliseconds: number): number {
        return milliseconds / (1000 * 60 * 60);
    }

    getDueDays(inputDate: string | undefined): string {
        if (!inputDate) return '';

        const dueDate = new Date(inputDate);
        const currentDate = this.GetApplicationCurrentDateTime(); currentDate.setHours(0, 0, 0, 0);
        const timeDifference = dueDate.getTime() - currentDate.getTime();
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
        if (daysDifference < 0) {
            return `${-(daysDifference)} days ago`;
        } else if (daysDifference === 0) {
            return 'Today';
        } else if (daysDifference === 1) {
            return 'Tomorrow';
        } else if (daysDifference <= 90) {
            return `Due in ${daysDifference} days`;
        } else {
            const monthsDifference = Math.floor(daysDifference / 30);
            if (monthsDifference < 12) {
                return `Due in ${monthsDifference} months`;
            } else {
                const yearsDifference = Math.floor(monthsDifference / 12);
                return `Due in ${yearsDifference} years`;
            }
        }
    }

    convertDateToString(date: Date): string {
        const datePipe = new DatePipe('en-US');
        const formattedDateTime = datePipe.transform(date, 'yyyy-MM-ddTHH:mm:ss')?.toString();
        return formattedDateTime!;
    }
    
    isDateValid(date: string | null): boolean {
        if (!date) {
            return false;
        }
        const parsedDate = moment(date, this.getMonthFormat()).year()
        return parsedDate > 1900;
    }

    public ConvertDateStringToDate(inputDate: string | null): Date | null{
        if (!inputDate)
            return null

        return new Date(inputDate);
    }

    getTomorrowDate(): Date {
        const currentDate = this.getDateWithStartTime(this.GetApplicationCurrentDateTime().toString());
        currentDate.setDate(currentDate.getDate() + 1);
        return currentDate;
    }

    subtractDaysWithDate(date: Date, numberOfDays: number): Date {
        date.setDate(date.getDate() - numberOfDays);
        return date;
    }

    // All API requests involving date values should now use this methods.
    startDateWithoutTimezone(string: string): string | null {
        const date = moment(string).toDate().setHours(0, 0, 0, 0);
        const formattedStartDate = this.datePipe.transform(date, 'yyyy-MM-ddTHH:mm:ss');
        return formattedStartDate;
    }

    endDateWithoutTimezone(string: string): string | null {
        const date = moment(string).toDate().setHours(23, 59, 59, 999);
        const formattedEndDate = this.datePipe.transform(date, 'yyyy-MM-ddTHH:mm:ss');
        return formattedEndDate;
    }

    formatDateAndTimeToString(date: Date | undefined): string | null {
        return this.datePipe.transform(date, 'MM/dd/yyyy hh:mm a');
    }
    
    convertStringToDate(inputDate: string): Date {
        return moment(inputDate).toDate();
    }

    isValidDate(dateStr: string | null): boolean {
        if (dateStr === null) return true;
        const date = this.convertStringToDate(dateStr);
        const maxYear = this.GetApplicationCurrentDateTime().getFullYear() + 100;
        const year = date.getFullYear();
        if (year < 1900 || year > maxYear) {
            return false;
        }
        return true;
    }
}
