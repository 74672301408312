import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularPinturaModule } from '@pqina/angular-pintura';
import { WebcamModule } from 'ngx-webcam';

import { DocumentUploadComponent } from './components/documents/document-upload/document-upload.component';
import { DocumentUploadEffects } from './store/document-upload-store/document-upload.effect';
import { documentUploadReducer } from './store/document-upload-store/document-upload.reducer';
import { DocumentDownloadComponent } from './components/documents/image-downloand/image-download.component';
import { DocumentDownloadEffects } from './store/document-download-store/document-download.effect';
import { documentDownloadReducer } from './store/document-download-store/document-download.reducer';
import { documentCoreReducer } from './store/document-core-store/document-core.reducer';
import { DocumentCoreEffect } from './store/document-core-store/document-core.effect';
import { SharedFeatureModule } from '../shared/shared-feature.module';
import { ImageEditorToolComponent } from './components/image-editor/image-editor-tool/image-editor-tool.component';
import { BaseDocumentUploadComponent } from './components/base-document-upload/base-document-upload.component';
import { WebcamComponent } from './components/webcam/webcam.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { SignaturePadComponent } from './components/signature-pad/signature-pad.component';
import { IconsModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DownloadDocumentFileService } from './service/download-document-file.service';
import { uploadDocumentFileService } from './service/upload-document-file.service';
import { UserFeatureCoreModule } from '../user/user-feature-core.module';
import { DynamsoftComponent } from './components/dynamsoft/dynamsoft.component';
import { DocumentEditorFeatureModule } from '../shared/module/document-editor.module';

@NgModule({
  declarations: [
    DocumentUploadComponent,
    DocumentDownloadComponent,
    ImageEditorToolComponent,
    BaseDocumentUploadComponent,
    WebcamComponent,
    DynamsoftComponent,
    ImageViewerComponent,
    SignaturePadComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    AngularPinturaModule,
    SharedFeatureModule,
    DocumentEditorFeatureModule,
    WebcamModule,
    IconsModule,
    InputsModule,
    UserFeatureCoreModule,
    StoreModule.forFeature('documentCoreStore', documentCoreReducer),
    StoreModule.forFeature('documentUploadStore', documentUploadReducer),
    StoreModule.forFeature('documentDownloadStore', documentDownloadReducer),
    EffectsModule.forFeature([ DocumentUploadEffects,DocumentDownloadEffects,DocumentCoreEffect]),
 
  ],
  providers: [
    DownloadDocumentFileService,
    uploadDocumentFileService
  ],
  exports: [
    DocumentUploadComponent,
    DocumentDownloadComponent,
    ImageEditorToolComponent,
    ImageViewerComponent,
    SignaturePadComponent
  ],
  bootstrap: [ImageEditorToolComponent],
})
export class DocumentFeatureModule { }
