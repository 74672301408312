import { environment } from "src/environments/environment";


const PATIENT_API_BASE_URL = environment.application.api_base_patient_url;
function patientApiUrl(endpoint: string) {return `${PATIENT_API_BASE_URL}${endpoint}`;}

const CORE_API_BASE_URL = environment.application.api_base_core_url;
function coreApiUrl(endpoint: string) { return `${CORE_API_BASE_URL}${endpoint}`;}

const COMMUNICATION_API_BASE_URL = environment.application.api_base_communication_url;
function communicationApiUrl(endpoint: string) {return `${COMMUNICATION_API_BASE_URL}${endpoint}`;}

const PRACTICE_API_BASE_URL = environment.application.api_base_practice_url;
function practiceApiUrl(endpoint: string) {return `${PRACTICE_API_BASE_URL}${endpoint}`}

const SIGNALR_API_BASE_URL = environment.application.api_base_signalr_api_url;
function signalrApiUrl(endpoint: string) {
    return `${SIGNALR_API_BASE_URL}${endpoint}`;
}

export const patientCommunicationApiAsset = {
    savePatientCommunication: patientApiUrl("PatientCommunication/SavePatientCommunication"),
    savePatientCommunicationWithHistory: patientApiUrl("PatientCommunication/SavePatientCommunicationByPatientIdWithHistory"),
    saveBulkPatientCommunication: patientApiUrl("PatientCommunication/SaveBulkPatientCommunication"),
    getPatientCommunicationTypes: coreApiUrl("Core/GetPatientCommunicationTypes"),
    getPatientCommunicationCategories: coreApiUrl("Core/GetPatientCommunicationCategories"),
    getPatientCommunicationSubCategories: coreApiUrl("Core/GetPatientCommunicationSubCategories"),
    sendSms: signalrApiUrl("Notification/SendSms"),
    sendEmail: communicationApiUrl("Communication/SendEmail"),
    getPracticeLocationById: practiceApiUrl("PracticeLocation/GetPracticeLocationById"),
    getPatientCommunications: patientApiUrl("PatientCommunication/GetPatientCommunications"),
    getDialToken: communicationApiUrl("Communication/GetDialToken"),
    getRecentPatientChatCollection: communicationApiUrl("Communication/GetRecentPatientChatCollection"),
    getArchivedPatientCollection: communicationApiUrl("Communication/GetArchivedPatientChatCollection"),
    getUnreadChatCollection: communicationApiUrl("Communication/GetUnreadChatCollection"),
    getPatientChatCollection: communicationApiUrl("Communication/GetPatientChatCollection"),
    getPatientByPhone: patientApiUrl("Patient/GetPatientByPhone"),
    getReadPatientCallLog: communicationApiUrl("Communication/ReadPatientCallLog"),
    getRecentCallLogs: communicationApiUrl("Communication/ReadCallLog"),
    callAnswered: signalrApiUrl("Notification/CallAnswered"),
    getPatientFollowupStatuses: coreApiUrl("Core/GetFollowupStatuses"),
    markAsRead: signalrApiUrl("Notification/MarkAsRead"),
    archivePatientChat: communicationApiUrl("Communication/ArchiveChat"),
    unArchivePatientChat: communicationApiUrl("Communication/UnArchiveChat"),
    markAsReadPatientChat: communicationApiUrl("Communication/MarkAsRead"),
    markAsUnReadPatientChat: communicationApiUrl("Communication/MarkAsUnRead"),
}