import { HttpClient, HttpEvent, HttpEventType, HttpProgressEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { documentApiAsset } from '../constant/document-api-asset';
import { Observable, catchError, map, throwError } from 'rxjs';
import { DocumentUpload, DocumentUploadResponse } from '../store/document-upload-store/document-upload.state';
import { DocumentCategory } from '../store/document-core-store/document-core-state';
import { uploadType } from '../constant/document-message.constant';

@Injectable({
  providedIn: 'root'
})

export class DocumentAPIService {

  constructor(private http: HttpClient) { }

  getDocumentCategories(): Observable<DocumentCategory[]> {
    return this.http.get<DocumentCategory[]>(documentApiAsset.getDocumentSubcategories);
  }
   
  downloadDocument(documentGuid: string): Observable<Blob> {
    const param: { 'documentGuid':string } = { 'documentGuid': documentGuid };
    return this.http.get(documentApiAsset.getDocumentStream, { params: param, responseType: 'blob' });
  }

  getWordDocumentAsSfdtByBlobUrl(blobUrl: string): Observable<string> {
    const apiEndpoint = `${documentApiAsset.getWordDocumentAsSfdtByBlobUrl}?blobUrl=${encodeURIComponent(blobUrl)}`;
    return this.http.get<string>(apiEndpoint);
  }

  uploadPatientImage(file: File, formData:FormData): Observable<DocumentUpload> {
    formData.get('PatientImageId')==="0"? formData.append('OriginalImage', file):
      formData.append('CroppedImage', file);

    const documentUpload: DocumentUpload = {
      progress: 0,
      completed: false,
      eventType: '',
    };
    return this.http.post(documentApiAsset.savePatientImage, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((event: HttpEvent<unknown>) => this.getEventMessage(event as  HttpEvent<DocumentUploadResponse>, documentUpload)),
      catchError((error) => {
        return throwError(() => new Error(error?.message || error || 'Something went wrong during the upload. Please try again.'));
      })
    );
  }

  uploadDocument(formData: FormData,uploadModuleType: string): Observable<DocumentUpload> {
    const  url = uploadModuleType === uploadType.toothImage? documentApiAsset.savePatientImage : documentApiAsset.saveDocument;
    const documentUpload: DocumentUpload = {
      progress: 0,
      completed: false,
      eventType: '',
    };
    return this.http.post(url, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((event: HttpEvent<unknown>) => this.getEventMessage(event as  HttpEvent<DocumentUploadResponse>, documentUpload)),
      catchError((error) => {
        return throwError(() => new Error(error?.message || error || 'Something went wrong during the upload. Please try again.'));
      })
    );
  }

  downloadDocumentForUnAuthurizedUser(documentGuid: string): Observable<Blob> {
    const param: { 'documentGuid':string } = { 'documentGuid': documentGuid };
    return this.http.get(documentApiAsset.downloadDocumentForUnAuthurizedUser, { params: param, responseType: 'blob' });
  }


  private getEventMessage(event: HttpEvent<DocumentUploadResponse>, documentUpload: DocumentUpload): DocumentUpload {
    documentUpload.eventType = event.type.toString();
    switch (event.type) {
      case HttpEventType.UploadProgress: {
        documentUpload.progress = this.fileUploadProgress(event);
        break;
      }
      case HttpEventType.Response: {
        if(event.body){
        documentUpload.progress = 100;
        documentUpload.documentUploadResponse = event.body;
        documentUpload.completed = true;
        }
        break;
      }
      default: {
        break;
      }
    }
    return documentUpload;
  }

  private fileUploadProgress(event: HttpProgressEvent): number {
    const totalBytes = event.total;
    if (totalBytes === undefined) return 0;
  
    const percentDone = Math.round(100 * event.loaded / totalBytes);
    return percentDone;
  }
}