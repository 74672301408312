import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DateFormatPipe } from '../pipes/date-format.pipe';
import { ValidationErrorComponent } from '../components/validation-error/validation-error.component';
import { FormSkeletonComponent } from '../components/spinner/form-skeleton/form-skeleton.component';
import { CardComponent } from '../components/card/card.component';
import { MultiSelectCustomControlComponent } from '../components/form-controls/multiselect-custom-control/multiselect-custom-control.component';
import { PageNotFoundComponent } from '../components/error-pages/page-not-found.component';

@NgModule({
    declarations: [
        FormSkeletonComponent,
        CardComponent,
        MultiSelectCustomControlComponent,
        ValidationErrorComponent,
        PageNotFoundComponent,
        DateFormatPipe
    ],
    imports: [
        ReactiveFormsModule,
        CommonModule,
        NgSelectModule,
        FormsModule
    ],
    exports: [
        FormSkeletonComponent,
        CardComponent,
        MultiSelectCustomControlComponent,
        ValidationErrorComponent,
        PageNotFoundComponent,
        DateFormatPipe
    ],
})

export class CoreAppFormFeatureModule { }