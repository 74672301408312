import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class VisibilityControlServiceComponent {
  private visibilityMap: { [key: string]: BehaviorSubject<boolean> } = {};
  constructor() { 
    this.visibilityMap['isSyncfusion'] = new BehaviorSubject<boolean>(false);
    this.visibilityMap['isImage'] = new BehaviorSubject<boolean>(false);
  }

  visibilityCloseEvent(componentName: string, isVisible: boolean): void {
    if (this.visibilityMap[componentName]) {
      this.visibilityMap[componentName].next(isVisible);
    } else {
      this.visibilityMap[componentName] = new BehaviorSubject<boolean>(isVisible);
    }
  }

  getVisibilityObservable(componentName: string): Observable<boolean> {
    if (!this.visibilityMap[componentName]) {
      this.visibilityMap[componentName] = new BehaviorSubject<boolean>(false);
    }
    return this.visibilityMap[componentName].asObservable();
  }

}
