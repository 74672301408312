import { Component, ViewEncapsulation, ViewChild, Input, SimpleChanges } from '@angular/core';
import { CustomToolbarItemModel, DocumentEditorContainerComponent, PageSetupDialogService, PrintService, SearchService, ToolbarService } from '@syncfusion/ej2-angular-documenteditor';
import { showSpinner ,hideSpinner,createSpinner, } from '@syncfusion/ej2-popups';
import { VisibilityControlServiceComponent } from '../../visibility-control-service/visibility-control-service.component';

@Component({
    selector: 'app-form-control-syncfusion-editor',
    template: `<div class="syncfusion-control">
                <label *ngIf="label" [for]="label">{{ label }}
                    <span *ngIf="required" class="required_field ms-1">*</span>
                </label>
                <div *ngIf="enableFullView" class="btn btn-sm btn-light-primary m-2 mb-2" (click)="syncfusionHideShowEvent()">
                {{isViewName}} View</div>
                <ejs-documenteditorcontainer #document_editor class="sf-Document-Editor" id="syncontainer" [height]="height" style="display:block" serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/" 
                [enableToolbar]=true [toolbarItems]=items (toolbarClick)="onToolbarClick($event)" [showPropertiesPane]="showPropertiesPane"
                (contentChange)="contentChange($event)" (created)="onCreate()">
                </ejs-documenteditorcontainer>
              
                <div *ngIf="required && editorContentInValid" class="text-danger f-12">
                    {{ errorMessage }}
                </div>
                <div *ngIf="documentEditorErrorMessage" class="text-danger f-12">
                {{ documentEditorErrorMessage }}
                </div>
              </div>
              `,
    styles: [`
            .e-print-icon{
                background-image: url('/assets/images/svg/print-receipt.svg'); min-width: 20px !important;width:20px !important;
                 background-repeat: no-repeat;background-size: contain; min-height:24px !important;
            }
            .sf-Document-Editor{
                .e-de-background{background: #E6E6E6 !important;}
                .e-tbar-btn-text{display:flex !important;
                .e-de-text-wrap{margin-left:2px;line-height: inherit !important}}
            }
             `],
    encapsulation: ViewEncapsulation.None,
    providers: [ToolbarService, PrintService,PageSetupDialogService, SearchService]
})

export class SyncfusionDocumentEditor {
    @Input() editorInputHTML: any = ''
    @Input() label!: string;
    @Input() required?: boolean | false;
    @Input() errorMessage: string = '';
    @Input() showPropertiesPane: boolean = true;
    @Input() height: any = 'auto';
    @Input() imageUrls?: any[] | null;
    @Input() textReplaceInputs?: any[] | null;
    @Input() acceptFields?: string[] | null;
    @Input() readOnly? = false;
    @Input() enableFullView? = true;

    isFullViewedSyncfusion: boolean = false;  
    isViewName: string = 'Full';
    @ViewChild('document_editor')
    
    public documentEditorContainer?: DocumentEditorContainerComponent;
    public toolItem: CustomToolbarItemModel = {
        prefixIcon: "e-print-icon",
        tooltipText: "Print",
        text: "Print",
        id: "Print",
        
    };

    public items = [this.toolItem, 'New', 'Open', 'Separator', 'Undo', 'Redo', 'Separator', 'Image', 'Table', 'Hyperlink', 'Separator', 'Header', 'Footer', 'PageSetup', 'Break', 'Separator', 'Find', 'Separator'];

    editorContentInValid: boolean = false;
    documentEditorErrorMessage : string = '';
    constructor(private visibilityControlServiceComponent: VisibilityControlServiceComponent) {
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['editorInputHTML'])
            this.onCreate();

        if(changes['textReplaceInputs'])
            this.onInsertText();
    }

    ngAfterViewInit(): void {
        if (this.documentEditorContainer && (this.documentEditorContainer as DocumentEditorContainerComponent).documentEditor?.isDocumentLoaded) {
            this.setPageSetup(this.documentEditorContainer.documentEditor);
            this.documentEditorContainer.documentEditor.enableAutoFocus = true;
            this.documentEditorContainer.documentEditor.scrollToPage(1)
        }
        createSpinner({
            target: (document.getElementById('syncontainer') as HTMLElement) as HTMLElement
        });
    }

    onCreate(): void {
        this.items = this.readOnly ? [this.toolItem] : this.items;
        if (this.documentEditorContainer && (this.documentEditorContainer as DocumentEditorContainerComponent).documentEditor?.isDocumentLoaded) {
            this.documentEditorContainer.documentEditor.isReadOnly = this.readOnly?? false;
            this.documentEditorContainer.documentEditor.openBlank();
            let inputContent = this.editorInputHTML && Array.isArray(this.editorInputHTML) ? this.editorInputHTML[0] : this.editorInputHTML
            if (inputContent){
                this.documentEditorContainer?.documentEditor.open(inputContent);
                this.onInsertImage();
                this.onInsertText();
                this.removeUnUsedText(this.acceptFields??[])
            }

            this.clearValidation();
            this.setPageSetup(this.documentEditorContainer.documentEditor)
            this.documentEditorContainer.documentEditor.documentEditorSettings.optimizeSfdt =  true;
        }
    }

    contentChange(event: any){
        this.editorContentInValid = false;
    }
    
    setPageSetup(documentEditor: any) {
        const sectionFormat = documentEditor.selection.sectionFormat;
        sectionFormat.topMargin = 20;
        sectionFormat.bottomMargin = 20;
        sectionFormat.leftMargin = 20;
        sectionFormat.rightMargin = 20;
    }

    public onToolbarClick(args: any): void {
        switch (args.item.id) {
            case 'Print':
                this.documentEditorContainer?.documentEditor?.print();
                break;
        }
    };

    public async converDocumentToHTML() {
        if (!this.documentEditorContainer) {
            console.error('DocumentEditor instance is not initialized.');
            this.editorContentInValid = this.required!;
            return "";
        }

        if (this.documentEditorContainer.documentEditor?.isDocumentEmpty) {
            this.editorContentInValid = this.required!;
            return "";
        }
    
        let documentContent = this.documentEditorContainer.documentEditor.serialize();
        if (this.editorContentInValid)
            return '';
        else
            return documentContent;
    };

    clearValidation(){
        this.documentEditorErrorMessage = '';
        this.editorContentInValid = false;
    }

    public onInsertImage(): void {
        if (this.documentEditorContainer && (this.documentEditorContainer as DocumentEditorContainerComponent).documentEditor?.isDocumentLoaded) {
            const documentEditor = (this.documentEditorContainer as DocumentEditorContainerComponent).documentEditor!;
            if (documentEditor && this.imageUrls && this.imageUrls.length > 0 && this.imageUrls.some(x => x.patientImageUrl) ) {
               showSpinner((document.getElementById('syncontainer') as HTMLElement));
               
                this.imageUrls?.forEach((element: any) => {
                    documentEditor.search.findAll(element.patientImageTag);
                    let searchLength: number = documentEditor.search.searchResults.length;
                    for (let i = 0; i < searchLength; i++) {
                        documentEditor.search.searchResults.index = i;
                        const imageWidth = element.patientImageWidth ? Number(element.patientImageWidth.replace('px', '')) : 244;
                        const imageHeight = element.patientImageHeight ? Number(element.patientImageHeight.replace('px', '')) : 244;
                        documentEditor.editor.insertImage(element.patientImageUrl, imageWidth, imageHeight);
                    }
                });
                setTimeout(() => {
                    hideSpinner((document.getElementById('syncontainer') as HTMLElement));
                    documentEditor.scrollToPage(1);
                    documentEditor.enableAutoFocus = true;
                    documentEditor.search.clearSearchHighlight();
                }, 2000);
            }
        }
    }

    public onInsertText(): void {
        if (this.documentEditorContainer && (this.documentEditorContainer as DocumentEditorContainerComponent).documentEditor?.isDocumentLoaded) {
            const documentEditor = (this.documentEditorContainer as DocumentEditorContainerComponent).documentEditor!;
            if (documentEditor && this.textReplaceInputs && this.textReplaceInputs.length > 0 && this.textReplaceInputs.some(x => x.inputKey)) {
                const bookmarks = documentEditor.getBookmarks();
                this.textReplaceInputs.forEach((element: any) => {
                    const isPresent = bookmarks.some(bookmark => bookmark.includes(element.uniqueKey));
                    if (isPresent) {
                        var temprorayBookmark = bookmarks.filter(bookmark => bookmark.includes(element.uniqueKey));
                        if (temprorayBookmark && temprorayBookmark.length > 0) {
                            temprorayBookmark.forEach(key => {
                                documentEditor.selection.selectBookmark(key, true);
                                const value = element.inputValue ? element.inputValue.trim() : element.inputKey;
                                documentEditor.editor.insertText(value);
                            });
                        }
                        else {
                            documentEditor.selection.selectBookmark(element.uniqueKey, true);
                            const value = element.inputValue ? element.inputValue.trim() : element.inputKey;
                            documentEditor.editor.insertText(value);
                        }
                    }
                    else {
                        documentEditor.search.findAll(element.inputKey);
                        const searchLength: number = documentEditor.search.searchResults.length;
                        for (let i = 0; i < searchLength; i++) {
                            documentEditor.search.searchResults.index = i;
                            const currentBookmark = documentEditor.getBookmarks();
                            const uniqueKey = `${element.uniqueKey}-${i}`
                            if (!currentBookmark.includes(uniqueKey)) {
                                documentEditor.editor.insertBookmark(uniqueKey);
                                if (element.inputValue.trim())
                                    documentEditor.editor.insertText(element.inputValue.trim());
                            }
                        }
                    }
                });
                documentEditor.search.clearSearchHighlight();
            }
        }
    }

    public removeUnUsedText(inputValues: string[] = []) {
        if (this.documentEditorContainer && (this.documentEditorContainer as DocumentEditorContainerComponent).documentEditor?.isDocumentLoaded) {
            const documentEditor = (this.documentEditorContainer as DocumentEditorContainerComponent).documentEditor!;
            if (documentEditor) {
                inputValues.forEach(inputKey => {
                documentEditor.search.findAll(inputKey);
                    const searchLength: number = documentEditor.search.searchResults.length;
                    for (let i = 0; i < searchLength; i++) {
                        documentEditor.search.searchResults.index = i;
                        documentEditor.editor.delete()
                    }
                });
                documentEditor.search.clearSearchHighlight();
            }
        }
    }

    syncfusionHideShowEvent() {
        this.isFullViewedSyncfusion = !this.isFullViewedSyncfusion;
        this.visibilityControlServiceComponent.visibilityCloseEvent('isSyncfusion', this.isFullViewedSyncfusion);
        this.isViewName = this.isFullViewedSyncfusion ? 'Normal' : 'Full';
    }
}