import { NgModule } from '@angular/core';
import { SyncfusionDocumentEditor } from '../components/form-controls/form-control-syncfusion-editor/form-control-syncfusion-editor.component';
import { DocumentEditorContainerModule } from '@syncfusion/ej2-angular-documenteditor';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        SyncfusionDocumentEditor
    ],
    imports: [
        DocumentEditorContainerModule,
        CommonModule
    ],
    exports: [
        SyncfusionDocumentEditor,
    ],
})

export class DocumentEditorFeatureModule { }