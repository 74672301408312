// Angular Import
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Project Import
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthGuard } from './features/user/services/authentication/auth.guard';
import { PageNotFoundComponent } from './features/shared/components/error-pages/page-not-found.component';
import { SessionTimeoutComponent } from './features/shared/components/error-pages/session-timeout.component';
import { NoPermissionComponent } from './features/shared/components/error-pages/no-permission.component';


const routes: Routes = [
  {
    path: 'online-scheduling',
    loadChildren: () => import('./features/online-schedule/online-schedule-feature.module').then((module) => module.OnlineSchedulingFeaturedModule),
  },
  {
    path: 'patient-referral',
    loadChildren: () => import('./features/patient/online-patient-referral-feature.module').then((module) => module.OnlinePatientReferralFeatureModule),
  },
  {
    path: 'media-viewer-landing',
    loadChildren: () => import('./features/letter-template/module/media-viewer.module').then((module) => module.MediaViewerModule),
  },
  {
    path: 'appointmentconfirmation',
    loadChildren: () => import('../app/features/scheduler/module/patient-appointment-confirmation.module').then((module) => module.PatientAppointmentConfirmationModule),
  },
  { path: 'session-timeout', component: SessionTimeoutComponent},
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./features/dashboard/dashboard-feature.module').then((module) => module.DashboardFeatureModule)
      },
      {
        path: 'login',
        loadChildren: () => import('./features/user/user-feature.module')
          .then(mod => mod.UserFeatureModule)
      },
      {
        path: 'practice',
        canActivate: [AuthGuard],
        loadChildren: () => import('./features/practice/practice-feature.module').then((module) => module.PracticeModule)
      },
      {
        path: 'patient',
        canActivate: [AuthGuard],
        loadChildren: () => import('./features/patient/patient-information-feature.module')
          .then((module) => module.PatientInformationFeatureModule)
      },
      {
        path: 'patientdashboard',
        loadChildren: () => import('./features/patientdashboard/patient-dashboard.module').then((module) => module.PatientDashboardModule)
      },
      {
        path: 'insurancedashboard',
        loadChildren: () => import('./features/insurance/insurance-feature.module').then((module) => module.InsuranceFeatureModule)
      },
      {
        path: 'schedule',
        loadChildren: () => import('./features/scheduler/scheduler.module').then((module) => module.SchedulerModule)
      },
      {
        path: 'payment',
        loadChildren: () => import('./features/payment/payment-feature.module').then((module) => module.PaymentModule)
      },
      {
        path: 'contractdashboard',
        loadChildren: () => import('./features/contract-plan-dashboard/contract-plan-dashboard-feature.module').then((module) => module.TreatmentContractPlanDashboardModule)
      },
      {
        path: 'patientinfo',
        loadChildren: () => import('./features/patient/patient-summary-feature.module').then((module) => module.PatientSummaryFeaturedModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('./features/practice/practice-feature.module').then((module) => module.PracticeModule)
      },
      {
        path:'report',
        loadChildren:() => import('./features/report/report-feature.module').then((module) => module.ReportFeatureModule)
      },
      {
        path:'chat',
        loadChildren:() => import('./features/dashboard/module/chat-dashboard.module').then((module) => module.ChatDashboardModule)
      },
      {
        path: 'self-checkin',
        loadChildren: () => import('./features/self-checkin/self-checkin.module').then((module) => module.SelfCheckInModule)
      },
      {
        path: 'task',
        canActivate: [AuthGuard],
        loadChildren: () => import('./features/task/task-feature.module').then((module) => module.TaskFeaturedModule)
      },
    ]
  },
  { path: 'no-access', component: NoPermissionComponent , canActivate: [AuthGuard] },
  { path: '**', component: PageNotFoundComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
